import {behaviours} from '../../../global/scripts/behaviours/behaviour'

import {SwiperModule} from 'swiper/types'
import inView from 'inview'

const BlockFeaturesSlider = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.blockFeaturesSlider')

        if (blocks.length === 0) {
            return
        }

        import(/* webpackChunkName: "slider" */ 'swiper').then((module) => {
            blocks.forEach((block) => init(block, module))
        })
    }

    const init = function (block: HTMLElement, module: typeof import('swiper')) {
        const Swiper = module.default
        const EffectModule = module.EffectCreative as SwiperModule
        const ThumbsModule = module.Thumbs as SwiperModule
        const NavigationModule = module.Navigation as SwiperModule
        const PaginationModule = module.Pagination as SwiperModule

        const baseClass = 'blockFeaturesSlider'
        const carouselElement = block.querySelector<HTMLElement>(`.${baseClass}__carousel`)
        const thumbNailsElement = block.querySelector<HTMLElement>(`.${baseClass}__thumbs`)

        const wrapperClass = `${baseClass}__items`
        const ThumbswrapperClass = `${baseClass}__thumbsWrapper`

        const slideClass = `${baseClass}__item`
        const thumbClass = `${baseClass}__thumbsItem`

        const navClass = 'slideNavigation__item'
        const buttonNext = block?.querySelector<HTMLElement>(`.${navClass}--next`)
        const buttonPrev = block?.querySelector<HTMLElement>(`.${navClass}--previous`)

        if (!carouselElement || !thumbNailsElement) {
            return false
        }

        const thumbs = new Swiper(thumbNailsElement, {
            modules: [NavigationModule],
            spaceBetween: 40,
            slidesPerView: 'auto',
            threshold: 5,
            wrapperClass: ThumbswrapperClass,
            slideClass: thumbClass,
            speed: 400,
            watchSlidesProgress: true,
            breakpoints: {},
        })

        const carousel = new Swiper(carouselElement, {
            modules: [EffectModule, ThumbsModule, NavigationModule, PaginationModule],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            slidesPerView: 1,
            spaceBetween: 15,
            threshold: 5,
            slideActiveClass: `${baseClass}__item--active`,
            slideVisibleClass: `${baseClass}__item--visible`,
            watchSlidesProgress: true,
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            thumbs: {
                swiper: thumbs,
                slideThumbActiveClass: `${baseClass}__thumbsItem--active`,
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            breakpoints: {
                1024: {
                    spaceBetween: 63,
                },
            },
        })

        carousel.on('activeIndexChange', function () {
            document.dispatchEvent(new Event('slideChange'))
            restartImages(carousel.slides[carousel.activeIndex])
        })

        inView(block, function (isInView: boolean) {
            if (!block.classList.contains('isInView') && isInView) {
                restartImages(carousel.slides[carousel.activeIndex])
            }
            block.classList.toggle('isInView', isInView)
        })
    }

    const restartImages = function (activeSlide: Element) {
        const image = activeSlide.querySelector<HTMLImageElement>('img')
        const picture = image?.closest<HTMLPictureElement>('picture')

        if (!image || !picture) {
            console.error('Image not found')
            return
        }

        if (!image.src.toString().includes('.svg')) {
            return
        }

        // Disable all image sources
        picture.querySelectorAll('source').forEach((source) => {
            const srcset = source.getAttribute('srcset')
            if (srcset) {
                source.setAttribute('data-srcset', srcset)
                source.setAttribute('srcset', '')
            }
        })

        // Disable image source
        image.setAttribute('data-src', image.src)
        image.setAttribute('src', '')

        setTimeout(() => {
            // Lazy load
            picture.querySelectorAll('source').forEach((source) => {
                source.setAttribute('srcset', source.getAttribute('data-srcset') + '?v=' + Math.random())
            })
            image.setAttribute('src', image.getAttribute('data-src') + '?v=' + Math.random())
        }, 300)
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(BlockFeaturesSlider)
